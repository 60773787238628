import React from "react";
import Link from "gatsby-link";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Banner from "../components/banner";

const NotFoundPage = ({ data }) => (
  <Layout>
    <SEO title="404: cette page n'existe plus" />
    <Banner
      title="Erreur 404"
      baseline="Oups cette page n'existe pas..."
      imageBanner={data.imageBanner.childImageSharp.fluid}
    />
    <section id="404" className="page-404 text-center">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-6 offset-lg-3">
            <h3>404</h3>
            <p>
              Nous sommes désolés, la page que vous souhaitez n’existe plus...
              L'un des liens ci-dessous pourra peut-être vous aider&nbsp;!
            </p>
            <Link className="btn btn-secondary btn-hover mr-30" to="/">
              <i className="fal fa-long-arrow-alt-right fa-2x" />
              Accueil
            </Link>
            <Link className="btn btn-secondary btn-hover" to="/offres">
              <i className="fal fa-long-arrow-alt-right fa-2x" />
              Nos Offres
            </Link>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);

export default NotFoundPage;

export const pageQuery = graphql`
  query {
    imageBanner: file(relativePath: { eq: "background/404.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
